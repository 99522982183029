import * as React from 'react';
import { GetAirportWithCity, getBannersAirlineID, getBannersAnd, getBannersCityID, getBlog, getSystemText } from '../store/flight';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';

import SearchFlight from '../inc/SearchFlightBar';
import { getControlText, getLanguageID, getUserID } from '../shared/functions';

import { frontloadConnect } from 'react-frontload';
import { bindActionCreators } from 'redux';
import { setBlogList2, setBlogList3, setBlogList4, setSystemText } from '../../../modules/general';
import Page from '../../components/page';

async function frontload(props) {
  let promise = new Promise((resolve, reject) => {
    /*getSystemText("1",getLanguageID()).then((val) => {
      props.setSystemText(val.data.d);
      //console.log('val=>',val)
      
    });*/

    getBlog('8', getLanguageID()).then(val => {
      props.setBlogList4(val.data.d);
      //console.log('göts=>',val)

      getBlog('9', getLanguageID()).then(val => {
        props.setBlogList2(val.data.d);

        getBlog('10', getLanguageID()).then(val => {
          props.setBlogList3(val.data.d);
          //console.log('val=>',val)

          resolve('ok');
        });
      });
    });
  });

  let result = await promise; // wait until the promise resolves (*)
}

function getBannerAsync(props, airlineID, cityID) {
  // console.log('getBannerAsync props: ',props);
  // console.log('getBannerAsync props airlineID: ',airlineID);

  let promise = new Promise((resolve, reject) => {
    if (props && props.location && props.location.pathname) {
      let paths = ([] = props.location.pathname.split('/'));
      if (paths.length > 2) {
        let currentPage = paths[1].toString().toLowerCase();
        if (currentPage == 'goedkope-vliegtickets' || currentPage == 'last-minute' || currentPage == 'vliegveld') {
          let page = paths[paths.length - 1];
          let page_title = paths[paths.length - 3] ? paths[paths.length - 3] : ' - ';
          GetAirportWithCity(page, getLanguageID().toString()).then(val2 => {
            if (currentPage == 'vliegveld') {
              let page = paths[paths.length - 2];
              props.setBannerTitle('Last Minute ' + page_title);
              GetAirportWithCity(page, getLanguageID().toString()).then(val3 => {
                // console.log('gökay111',val3.data.d);

                if (cityID > 0) {
                  getBannersCityID('banners4', '10', '3', getUserID(), airlineID).then(val => {
                    if (val && val.data && val.data.d && val.data.d.length == 0) {
                      getBannersCityID('banners4', '10', '3', getUserID(), cityID).then(val2 => {
                        props.setBanner(val2.data.d);
                      });
                    } else props.setBanner(val.data.d);
                  });
                } else {
                  getBannersAnd('banners4', '20', '3', '19', val2.data.d[0].ID, getUserID(), '').then(val => {
                    props.setBanner(val.data.d);

                    //   console.log('gökay1',val.data.d);
                    resolve('ok');
                  });
                }
              });
            } else {
              props.setBannerTitle(page_title.toUpperCase().replace('-', ' ') + ' ' + page.toUpperCase());
              if (val2 && val2.data && val2.data.d && val2.data.d.length > 0) {
                if (cityID > 0) {
                  getBannersCityID('banners4', '10', '3', getUserID(), airlineID).then(val => {
                    if (val && val.data && val.data.d && val.data.d.length == 0) {
                      getBannersCityID('banners4', '10', '3', getUserID(), cityID).then(val2 => {
                        props.setBanner(val2.data.d);
                      });
                    } else props.setBanner(val.data.d);
                  });
                } else {
                  getBannersAnd('banners4', '20', '3', '19', val2.data.d[0].ID).then(val => {
                    props.setBanner(val.data.d);

                    //console.log('gökay2',val.data.d);

                    resolve('ok');
                  });
                }
              } else {
                resolve('ok');
              }
            }
          });
        } else if (currentPage == 'vliegtickets') {
          if (paths.length == 4 && paths[3].indexOf('-') > -1) {
          } else {
            // console.log('Gökay geldi: ',airlineID);
            // console.log('Gökay geldi: ',props);
            // console.log('Gökay geldi: ',cityID);
            let page = paths[paths.length - 1];
            let page_title = paths[paths.length - 3];
            props.setBannerTitle(page_title.toUpperCase() + ' ' + page.toUpperCase());
            if (cityID > 0) {
              getBannersCityID('banners4', '10', '3', getUserID(), airlineID).then(val => {
                if (val && val.data && val.data.d && val.data.d.length == 0) {
                  getBannersCityID('banners4', '10', '3', getUserID(), cityID).then(val2 => {
                    props.setBanner(val2.data.d);
                  });
                } else props.setBanner(val.data.d);
              });
            } else {
              GetAirportWithCity(page, getLanguageID().toString()).then(val2 => {
                if (val2 && val2.data && val2.data.d && val2.data.d.length > 0) {
                  getBannersAnd('banners4', '20', '3', '19', val2.data.d[0].ID).then(val => {
                    props.setBanner(val.data.d);

                    resolve('ok');
                  });
                } else {
                  //console.log('enterasan',val2);
                  resolve('ok');
                }
              });
            }
          }
        } else {
          // let airlineID="";

          // console.log('currentPage:',props);

          if (currentPage === 'maatschappij') {
            let airl = paths[2].toUpperCase().replace('-', ' ');
            let ozeltitle = '';

            getSystemText('1', getLanguageID()).then(val => {
              //console.log('Gökay airl: ',airl);
              if (airl === 'CORENDON') {
                // airlineID = "34";
                ozeltitle = getControlText('1043', val.data.d).Text;
              } else if (airl === 'ONUR AIR') {
                // airlineID = "24";
                ozeltitle = getControlText('1044', val.data.d).Text;
              } else if (airl === 'PEGASUS AIRLINES') {
                // airlineID = "17";
                ozeltitle = getControlText('1045', val.data.d).Text;
              } else if (airl === 'TRANSAVIA') {
                // airlineID = "18";
                ozeltitle = getControlText('1046', val.data.d).Text;
              } else if (airl === 'SUNEXPRESS AIRLINES') {
                // airlineID = "20";
                ozeltitle = getControlText('1047', val.data.d).Text;
              } else if (airl === 'TURKISH AIRLINES') {
                // airlineID = "51";
                ozeltitle = getControlText('1048', val.data.d).Text;
              } else if (airl === 'ATLASGLOBAL AIRLINES') {
                // airlineID = "47";
                ozeltitle = getControlText('1049', val.data.d).Text;
              }
              props.setSystemText(val.data.d, 'Dyn');
              props.setBannerTitle(ozeltitle);
              resolve('ok');
            });
          } else {
            props.setBannerTitle('Last Minute');
            resolve('ok');
          }

          if (!airlineID) airlineID = 0;
        }

        if (airlineID > 0 || cityID == 0) {
          getBannersAirlineID('banners4', '10', '3', '19', '12', getUserID(), airlineID).then(val => {
            if (val && val.data && val.data.d && val.data.d.length == 0) {
              getBannersAirlineID('banners4', '10', '3', '19', '12', getUserID(), 0).then(val2 => {
                props.setBanner(val2.data.d);
              });
            } else props.setBanner(val.data.d);
          });
        }

        if (airlineID == 0 && cityID == 0) {
          getBannersAirlineID('banners4', '10', '3', '19', '12', getUserID(), airlineID).then(val => {
            if (val && val.data && val.data.d && val.data.d.length > 0) {
              props.setBanner(val.data.d);
            }
          });
        }
      } else resolve('ok');
    } else resolve('ok');
  });

  return promise;
}

class blog extends React.Component {
  constructor(props) {
    super(props);
    {
      this.state = {
        id: 0,
        pageName: '',
        slider_string: ''
      };
    }
  }

  showDelay(slidenumber, photolist) {
    const min = 1;
    const max = photolist.length;
    const rand = Math.floor(min + Math.random() * (max - min)) - 1;

    if (slidenumber === '0' || slidenumber === '') {
      this.setState({
        slider_string: photolist[rand]
      });
    }

    //setTimeout(() => this.showDelay(this.state.slider_string,photolist), 20000);
  }

  componentDidMount() {
    ReactGA.pageview(this.props.location.pathname + this.props.location.search);

    let fotolist = ['/img/bg/genel_42.jpg'];

    setTimeout(() => this.showDelay('0', fotolist), 1);
  }

  htmlEntities(str) {
    return String(str)
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;');
  }

  render() {
    let title = '',
      description = '',
      blogtitle = '';
    if (
      this.props &&
      this.props.general &&
      this.props.general.systemText &&
      this.props.general.systemText &&
      this.props.general.systemText.length > 0
    ) {
      title = getControlText('1074', this.props.general.systemText).Text;
      description = getControlText('1075', this.props.general.systemText).Text;
      blogtitle = getControlText('1076', this.props.general.systemText).Text;
    }

    const blogIds2 = [];
    const blogIds3 = [];
    const blogIds4 = [];
    var blogs2 = [];
    var blogs3 = [];
    var blogs4 = [];

    if (this.props && this.props.general && this.props.general.blogList2 && this.props.general.blogList3 && this.props.general.blogList4) {
      blogs2 = this.props.general.blogList2;
      blogs3 = this.props.general.blogList3;
      blogs4 = this.props.general.blogList4;
    }

    if (blogs2) {
      let i = 1;
      blogs2.forEach(function(row) {
        var divStyle4 = '';
        row.images.forEach(function(rowwt) {
          if (rowwt.url == '') {
            divStyle4 = {
              backgroundImage: 'url(' + require(`../../img/Content/Dynamic/Big/${rowwt.FileName}`) + ')'
            };
          }
        });

        if (i <= 2) {
          blogIds2.push(
            <div className="col-md-6 ">
              <div className="theme-blog-item _br-2 theme-blog-item-center theme-blog-item-white">
                <a className="theme-blog-item-link" href={row.sitemapUrl}>
                  {' '}
                </a>
                <div className="banner" style={{height:"300px"}}>
                  <div className="banner-bg" style={divStyle4}>
                    {' '}
                  </div>
                  <div className="banner-caption banner-caption-bottom banner-caption-">
                    <div className="blog_title">
                      <h2>{row.Title}</h2>
                      <div className="blog_date _ta-center">
                        {row.publishdate && row.publishdate.replace('00:00:00', '') + '/'} <span className="author"> {row.author} </span>
                      </div>
                      <p>{row.Keywords}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }
        i = i + 1;
      });
    }

    if (blogs3) {
      let i = 1;
      blogs3.forEach(function(row) {
        var divStyle4 = '';
        row.images.forEach(function(rowwt) {
          if (rowwt.url == '') {
            divStyle4 = {
              backgroundImage: 'url(' + require(`../../img/Content/Dynamic/Medium/${rowwt.FileName}`) + ')'
            };
          }
        });

        if (i <= 3) {
          blogIds3.push(
            <div className="col-md-4 ">
              <div className="theme-blog-item _br-2 theme-blog-item-center theme-blog-item-white">
                <a className="theme-blog-item-link" href={row.sitemapUrl}>
                  {' '}
                </a>
                <div className="banner" style={{height:"300px"}}>
                  <div className="banner-bg" style={divStyle4}>
                    {' '}
                  </div>
                  <div className="banner-caption banner-caption-bottom banner-caption-">
                    <div className="blog_title">
                      <h2>{row.Title}</h2>
                      <div className="blog_date _ta-center">
                        {row.publishdate && row.publishdate.replace('00:00:00', '') + '/'} <span className="author"> {row.author} </span>
                      </div>
                      <p>{row.Keywords}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }
        i = i + 1;
      });
    }

    if (blogs4) {
      blogs4.forEach(function(row) {
        var divStyle4 = '';
        row.images.forEach(function(rowwt) {
          if (rowwt.url == '') {
            divStyle4 = {
              backgroundImage: 'url(' + require(`../../img/Content/Dynamic/Small/${rowwt.FileName}`) + ')'
            };
          }
        });
        blogIds4.push(
          <div className="col-md-3 ">
            <div className="theme-blog-item _br-2 theme-blog-item-center theme-blog-item-white">
              <a className="theme-blog-item-link" href={row.sitemapUrl}>
                {' '}
              </a>
              <div className="banner" style={{height:"300px"}}>
                <div className="banner-bg" style={divStyle4}>
                  {' '}
                </div>
                <div className="banner-caption banner-caption-bottom banner-caption-">
                  <div className="blog_title">
                    <h2>{row.Title}</h2>
                    <div className="blog_date _ta-center">
                      {row.publishdate && row.publishdate.replace('00:00:00', '') + '/'} <span className="author"> {row.author} </span>
                    </div>
                    <p>{row.Keywords}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    }

    var imgUrl = this.state.slider_string;
    var divStyle = {
      backgroundImage: 'url(' + imgUrl + ')'
    };

    return (
      <div className="bg_alternate">
        <Page id="profile" title={title} description={description}>
          {' '}
        </Page>

        <div className="bg_alternate blogum">
          <h1> {blogtitle}</h1>

          <div className="container">
            <div className="row row-col-gap  _ph-50 " data-gutter="20">
              {blogIds2}
            </div>

            <div className="row row-col-gap  _ph-50 " data-gutter="20">
              {blogIds3}
            </div>
          </div>
        </div>
        <div className="theme-hero-area front">
          <div className="theme-hero-area-bg-wrap">
            <div className="theme-hero-area-bg" style={divStyle} />
          </div>
          <div className="theme-hero-area-body">
            <div className="container">
              <div className="_pv-20 _pv-mob-50">
                <SearchFlight />
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row  _ph-50 _pv-10 ">{blogIds4}</div>
        </div>
      </div>
    );
  }
}

//export default connect((state) => (state))(blog);

function mapStateToProps(state) {
  return { ...state };
}

const mapDispatchToProps = dispatch => bindActionCreators({ setBlogList2, setBlogList3, setBlogList4, setSystemText }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(blog)
);
